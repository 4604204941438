import { Component, OnInit, OnDestroy, Inject, EventEmitter, Output } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { OverlayContainer } from '@angular/cdk/overlay';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-swap-day-dialog-card',
  templateUrl: './swap-day-dialog-card.component.html',
  styleUrls: ['./swap-day-dialog-card.component.scss']
})
export class SwapDayDialogCardComponent implements OnInit, OnDestroy {
  @Output() confirm: EventEmitter<boolean> = new EventEmitter();
  private subscription: Subscription;
  titleClass: string;

  // tslint:disable-next-line: max-line-length
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<any>, public overlayContainer: OverlayContainer, public breakpointObserver: BreakpointObserver) {
    overlayContainer.getContainerElement().classList.remove('mini-calendar-dialog_overlay');
    overlayContainer.getContainerElement().classList.add('swap-day-dialog_overlay');
  }

  ngOnInit(): void {
    this.subscription = this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet
    ]).subscribe(result => {
      if (result.matches) {
        this.setMobileLayout();
      } else {
        this.setDesktopLayout();
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
    this.confirm.emit(false);
  }

  requestService() {
    this.dialogRef.close();
    this.confirm.emit(true);
  }

  setMobileLayout() {
    this.titleClass = 'mat-headline u-margin-bottom-15';
    this.dialogRef.updateSize('343px', '288px');
  }

  setDesktopLayout() {
    this.titleClass = 'mat-display-1';
    this.dialogRef.updateSize('377px', '276px');
  }

  ngOnDestroy() {
    this.overlayContainer.getContainerElement().classList.remove('swap-day-dialog_overlay');
    this.subscription.unsubscribe();
  }

}
