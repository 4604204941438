<div class="service-calendar-dialog">
  <h1 class="{{ titleClass }}">{{ data.title }}</h1>
  <p class="mat-body-1">
    {{ data.description }}
  </p>
  <div mat-dialog-actions>
    <button
      mat-raised-button
      color="primary"
      (click)="readyToRemoveService()"
      [mat-dialog-close]="true"
    >
      {{ data.confirmButton }}
    </button>
    <button
      mat-raised-button
      (click)="closeDialog()"
      class="cancel-button medium"
    >
      {{ data.cancelButton }}
    </button>
  </div>
</div>
