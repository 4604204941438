import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  headerImage: string;

  constructor(breakpointObserver: BreakpointObserver, private title: Title) {
    breakpointObserver.observe([
      Breakpoints.Handset
    ]).subscribe(result => {
      if (result.matches) {
        this.headerImage = './../../../../../assets/images/backgrounds/settings/terms_and_conditions_header_mobile.jpg';
      } else {
        this.headerImage = './../../../../../assets/images/backgrounds/settings/terms_and_conditions_header_desktop.jpg';
      }
    });
  }

  ngOnInit(): void {
    this.title.setTitle('Bruce\'s | Terms And Conditions');
  }
}
