<app-page-header headerImage="{{ headerImage }}"></app-page-header>
<div class="terms-conditions">
  <div class="terms-conditions_header">
    <h1 class="mat-display-1">Our terms &amp; conditions</h1>
  </div>
  <div class="core-content">
    <p style="text-align: left; caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0);">
      <font face="Arial" size="3">Bruce's is a trading name of Bruce's Dog Walking Ltd, a company registered in England
        and Wales (company number 7602894).&nbsp;Registered address: Bruce's, Woodlands Lane, Stoke D'Abernon, Cobham,
        England, KT11 3QD.&nbsp;VAT Number: 102688033.</font>
    </p>
    <p style="text-align: left; caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0);">
      <font face="Arial" size="3">TERMS &amp; CONDITIONS ("the contract")</font>
    </p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">1. By a) enrolling a dog with or b) permitting a dog to attend or c) accepting the
          Services of&nbsp; Bruce's and its staff, the Client is deemed to have accepted these Terms and Conditions.
        </font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">2.&nbsp; Bruce's may act in the Client's absence as guardian of their dog and may
          perform or take any action which they deem necessary in order to protect and keep in good health the Client's
          dog.&nbsp; In the event of an emergency situation Bruce's have the authority to make important healthcare
          related decisions on behalf of the Client in the instance that the Client cannot be contacted and the matter
          is a dog welfare issue – in collaboration with our partnered vets.</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">3. Bruce's requires one month's notice of cancellation. The Client agrees to provide
          such notice or pay the amount that would be due during this notice period. The Client may cancel with notice
          at any time; there is no minimum term of contract.</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">4. For boarding bookings, where 30 days or more notice is provided there is no
          charge. Where less than 30 days notice but more than 7 days notice is given, 50% of the fee is chargeable to
          cover administrative and management costs as well as to cover the loss of business. Where less than 7 days'
          notice is provided 100% of the fee is chargeable on the same basis.</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">5.&nbsp; Bruce's operates a fixed monthly fee system for day care and doggy bus
          collection, whereby the Client pays a fixed monthly fee for a place at the day care centre and space on the
          bus on agreed regular day/s per week. Fees are calculated based on the 51 weeks a year that Bruce's is open,
          averaged into 12 monthly payments, i.e. each invoice is for 4.25 weeks. Therefore, if the Client does not send
          their dog for any reason, for example due to holiday or sickness, the monthly fee for the agreed regular days
          and doggy bus collection if applicable is still payable in full.</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">6. Clients signing up to Bruce's for 2 or more days per week on or after 1st May
          2023 may swap their agreed regular days on an ad hoc basis subject to availability providing advance notice of
          non attendance has been given. For all clients from 1st May 2023, swapped days must be used within 1 month,
          are non-refundable and cannot be exchanged for cash on leaving. It may be possible to add occasional extra
          days as required, subject to availability. Requests to do so should be made via the app or email. These
          additional days will be added to the Client's monthly invoice at the “Additional Day” daily rate agreed
          between Bruce's and the Client. It may be possible to add occasional extra days as required, subject to
          availability. Requests to do so should be made via the app or email. These additional days will be added to
          the Client's monthly invoice at the “Additional Day” daily rate agreed between Bruce's and the
          Client.&nbsp;&nbsp;&nbsp;&nbsp;</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">6.1 a. Full payment for services (agreed either at time of booking or subsequently)
          is to be made within 7 days of receipt of invoice or, by the date specified on the invoice. Time for payment
          shall be of the essence of the Contract. The preferred method of payment is collection by Direct Debit. If the
          Client fails to make any payment due to Bruce's under the Contract by the due date for payment, then the
          Client shall pay interest on the overdue amount at the rate of 8% per cent per annum above Barclays Bank Plc's
          base rate from time to time. Such interest shall accrue on a daily basis from the due date until actual
          payment of the overdue amount, whether before or after judgement. The Client shall pay the interest together
          with the overdue amount plus any and all additional administrative, debt collection costs and legal fees
          incurred.</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">b. The Client shall pay all amounts due under the Contract in full without any
          set-off, counterclaim, deduction or withholding (except for any deduction or withholding required by law).
          Bruce's may at any time, without limiting its other rights or remedies, set off any amount owing to it by the
          Client against any amount payable by Bruce's to the Client.</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">c. On termination of the Contract for any reason the Client shall immediately pay to
          Bruce's all of Bruce's outstanding unpaid invoices and interest and, in respect of services supplied but for
          which no invoice has been submitted, Bruce's shall submit an invoice, which shall be payable by the Client
          immediately on receipt.</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="background-color: transparent; color: rgb(30, 39, 48); white-space: pre-wrap;">
        <font face="Arial" size="3">6.2 Annual price adjustment. The charge for our services is subject to change
          annually in line with changes to our service delivery costs.&nbsp;Bruce's will always give The Client at least
          30 days' notice via email of the exact increase each year.&nbsp;</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">LIMITATION OF LIABILITY: THE CLIENT'S ATTENTION IS PARTICULARLY DRAWN TO THIS CLAUSE
        </font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;">
      <font size="3" face="Arial"><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">7.1
          The Client will take responsibility for any costs which may be incurred, by either veterinary or other, as a
          result of any damage, accident, or sickness caused to or by their dog and will pay any such costs or expenses
          on demand.</span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><br></span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">7.2
          Nothing in the Contract shall limit or exclude Bruce's liability for:</span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><br></span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">a.
          death or personal injury to a human being caused by its negligence, or the negligence of its employees, agents
          or subcontractors;</span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><br></span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">b.
          fraud or fraudulent misrepresentation; or</span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><br></span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">c.
          breach of the terms implied by section 2 of the Supply of Goods and Services Act 1982 (title and quiet
          possession) or any other liability which cannot be limited or excluded by applicable law.</span></font>
    </p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;">
      <font size="3" face="Arial"><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">7.3
          Subject to clause 7.2, Bruce's shall not be liable to the Client, whether in contract, tort (including
          negligence), for breach of statutory duty, or otherwise, arising under or in connection with the Contract
          for:</span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><br></span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">a.
          loss of profits;</span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><br></span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">b.
          loss of sales or business;</span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><br></span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">c.
          loss of agreements or contracts;</span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><br></span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">d.
          loss of anticipated savings;</span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><br></span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">e.
          loss of damage to goodwill; and</span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><br></span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">f.
          any indirect or consequential loss.</span></font>
    </p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;">
      <font size="3" face="Arial"><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">7.4
          Subject to clause 7.2, Bruce's total liability to the Client, whether in contract, tort (including
          negligence), breach of statutory duty, or otherwise, arising under or in connection with the Contract shall be
          limited to £1,000 or the total fees paid under the Contract, whichever is lower.</span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><br></span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">7.5
          The terms implied by sections 3 to 5 of the Supply of Goods and Services Act 1982 are, to the fullest extent
          permitted by law, excluded from the Contract.</span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><br></span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">7.6
          This clause 7 shall survive termination of the Contract.</span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><br></span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">7.7
          In agreeing to the figure set under clause 7.4 above the Client agrees that he/ she is able to insure the
          Client's dog under a pet insurance policy and is likewise able to insure home and property contents under a
          house and contents insurance policy. The Client is advised to notify his/her insurers of the day care
          arrangement in order to be covered under the pertinent insurance policies. The Client understands that Bruce's
          is only able to offer its competitive fees on the basis of its Clients insuring its pets appropriately.</span>
      </font>
    </p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">8. The Client's dog may be transported with other dogs in a Bruce's vehicle. The
          Client agrees that Bruce's cannot be held liable for death or injury to their dog in the event of a motor
          vehicle accident.</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">9. Bruce's may hold in possession the keys to the Client's home and the Client is
          responsible under clause 7.7 above for making arrangements with its own insurers with this regard.</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">10. Bruce's is an off-lead environment. The Client gives permission for Bruce's to
          walk their dog/s off the lead. The Client consents to their dog playing and interacting with other dogs in
          Bruce's secure field environment. None of Bruce's centres walk dogs on lead off-site, all activity takes place
          within Bruce's secure premises.</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">11. For dogs under and over one year, by sending their dog to Bruce's, the Client
          consents to Bruce's matching their dog to a group based on age, play style, temperament and size to ensure
          their experience is best suited at Bruce's. The Client consents to Bruce's making this decision based on the
          individual dog.</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">12. The Client has explicitly confirmed that their dog has no record of aggressive
          or anti-social behaviour and they have made a full and frank disclosure of any characteristic or trait that
          might make their dog unsuitable for socialising with other dogs.</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">13. Should the Client's dog be deemed to be unsuitable by Bruce's, Bruce's reserves
          the right to cancel the booking indefinitely, with immediate effect.</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">14.1 To ensure a safe, responsible dynamic, we insist that all male dogs over one
          year old must be neutered in order to attend. Females in season generally cannot attend for at least 4 weeks.
        </font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">15. The Client agrees to ensure that their dog/s will be kept up to date on all
          vaccinations, de-worming and de-fleaing.</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">16. If fleas, ticks or lice are noticed on the Client's dog, the Client will receive
          immediate communication to seek advice from their vet. Bruce's will not administer medication without the
          Client's consent. If the Client cannot be contacted the dog will be monitored and isolated if appropriate,
          until the dog is collected from day care.</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;">
      <font size="3" face="Arial"><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">17.</span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><br></span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">a.
          In the event of requiring emergency treatment, the Client agrees that the designated emergency vet of Bruce's
          is authorised to treat. In any other circumstances the Client will be contacted and treatment/appointments
          will take place through their dog's regular registered vet. The details of each centre's registered vet can be
          found at the reception of each centre, details can be shared and a certificate of partnership will be
          displayed.</span></font>
    </p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">b. The owner or designated main point of contact must be contacted to give consent
          for euthanasia in ALL circumstances where this is recommended as the course of action by a veterinary
          professional. Should the client and the dog's emergency contact not be contactable, veterinary advice will be
          followed. In the unfortunate circumstances of an emergency, where warranted Bruce's vows to keep a record of
          all euthanasia and the identity of the qualified veterinarian that carried it out.</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">18. Medicines instructed to be given to their dog during day care at Bruce's by the
          Client will be stored, used and disposed of in accordance with the instructions of the manufacturer. Medicines
          will only be administered to their dog with the Client's consent following veterinary advice.</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">19. The Client consents that crates may be used for rest breaks and feeding if
          required and in the event of exceptional circumstances to maintain dog and human safety as appropriate and
          only if absolutely necessary, their dog may be placed in a crate for a limited time. Dogs will not be placed
          in a crate for longer than one hour in any 8 hour period as this is the guidance under the Animal Welfare
          (Licensing of Activities Involving Animals)(England) Regulations 2018 – Statutory Guidance for providing day
          care for dogs.</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">20. Bruce's does not supply dog food. If the Client supplies their dog with a daily
          meal whilst at Bruce's then the Client understands their dog will be fed in a separated area in isolation from
          other dogs.</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">21. By attending Bruce's, the Client consents for Bruce's to feed their dog food
          supplied by the Client.</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">22. All dogs who attend Bruce's partake in an enrichment programme that involves
          socialisation, play, scents, snacks and interaction and by sending their dog to Bruce's the Client consents to
          their dog partaking in these activities, appropriate adjustments will be made to allow the participation of
          dogs with special requirements.</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">23. Bruce's is closed on bank holidays and the period between Christmas and New
          Year. Swap days are not available for the period between Christmas and New Year, Bruce's fees are calculated
          over 51 weeks of the year to take into account this closed period.&nbsp;</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">24. The Client gives consent for their dog's image to be used in photo or video
          format on Bruce's promotional material and social media or in any format that Bruce's considers appropriate.
        </font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">25. Force Majeure. Neither party shall be in breach of this Contract nor liable for
          delay in performing, or failure to perform, any of its obligations under this Contract if such delay or
          failure result from events, circumstances or causes beyond its reasonable control.</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;">
      <font size="3" face="Arial"><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">26.
          Entire Agreement</span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><br></span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">a.
          This agreement constitutes the entire agreement between the parties and supersedes and extinguishes all
          previous agreements, promises, assurances, warranties, representations and understandings between them,
          whether written or oral, relating to its subject matter.</span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><br></span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">b.
          Each party agrees that it shall have no remedies in respect of any statement, representation, assurance or
          warranty (whether made innocently or negligently) that is not set out in this agreement. Each party agrees
          that it shall have no claim for innocent or negligent misrepresentation or negligent misstatement based on any
          statement in this agreement.</span></font>
    </p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">27. Variation. No variation of the Contract shall be effective unless it is in
          writing and notified or accepted by Bruce's (or its appointed director(s)).</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;">
      <font size="3" face="Arial"><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">28.
          Waiver. A waiver of any right or remedy is only effective if given in writing and shall not be deemed a waiver
          of any subsequent breach or default. A delay or failure to exercise, or the single or partial exercise of, any
          right or remedy shall not:</span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><br></span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">a.
          waive that or any other right or remedy; or</span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><br></span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">b.
          prevent or restrict the further exercise of that or any other right or remedy.</span></font>
    </p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">29. Severance If any provision or part-provision of the Contract is or becomes
          invalid, illegal or unenforceable, it shall be deemed modified to the minimum extent necessary to make it
          valid, legal and enforceable. If such modification is not possible, the relevant provision or part-provision
          shall be deemed deleted. Any modification to or deletion of a provision or part-provision under this clause
          shall not affect the validity and enforceability of the rest of the Contract.</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;">
      <font size="3" face="Arial"><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">30.
          Notices.</span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><br></span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">a.
          Any notice or other communication given to a party under or in connection with the Contract shall be in
          writing, addressed to that party at its registered office or such other address as that party may have
          specified to the other party in writing in accordance with this clause, and shall be delivered personally, or
          sent by pre-paid first class post or other next working day delivery service, commercial courier, fax or email
          to the correct addressed email address if sent delivered-read (so email delivery and receipt can be
          acknowledged).</span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><br></span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">b.
          A notice or other communication shall be deemed to have been received: if delivered personally, when left at
          the Client's address provided by him/her; if sent by pre-paid first class post or other next working day
          delivery service to the same address, at 9.00 am on the second business day after posting; if delivered by
          commercial courier, on the date and at the time that the courier's delivery receipt is signed; or, if sent by
          fax or email one business day after transmission</span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><br></span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">c.
          The provisions of this clause shall not apply to the service of any proceedings or other documents in any
          legal action.</span></font>
    </p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">31. Third parties. No one other than a party to the Contract shall have any right to
          enforce any of its terms.</font>
      </span></p>
    <p dir="ltr"
      style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span
        style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">
        <font size="3" face="Arial">32. Governing law. The Contract, and any dispute or claim (including non-contractual
          disputes or claims) arising out of or in connection with it or its subject matter or formation shall be
          governed by,and construed in accordance with the law of England and Wales.</font>
      </span></p>
    <p style="text-align: left; caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0);">
      <font size="3" face="Arial"><span id="docs-internal-guid-df45f39e-7fff-4993-188b-2c8fb2db0d7c"></span></font>
    </p>
    <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:12pt;">
      <font size="3" face="Arial"><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">33.
          Jurisdiction. Each party irrevocably agrees that the courts of England and Wales shall have exclusive
          jurisdiction to settle any dispute or claim (including non-contractual dis</span><span
          style="color: rgb(30, 39, 48); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">putes
          or claims) arising out of or in connection with the Contract or its subject matter or formation.</span></font>
    </p>
  </div>
</div>